import styled from 'styled-components'

import { configuration } from 'mmfintech-checkout-commons'
import { getPaymentMethodImageUrl, isValidString } from 'mmfintech-commons'

import BankwireImage from '../../assets/methods/bank-transfer.png'
import CreditCardImage from '../../assets/methods/credit-card.png'
import InstantBankTransferImage from '../../assets/methods/instant-bank-transfer.png'
import MobileMoneyImage from '../../assets/methods/mobile-money.png'
import { PaymentMethodEnum } from 'mmfintech-commons-types'

export const PaymentMethodImage = ({ method }) => {
  //const url = `${configuration.readBackendConfig()}/api${getPaymentMethodImageUrl(method)}`
  // const webResource = useWebResource(url)

  const getImage = () => {
    if (isValidString(method)) {
      switch (method) {
        case PaymentMethodEnum.EMONEY:
        case PaymentMethodEnum.BANKWIRE:
        case PaymentMethodEnum.LOCAL_BANKWIRE:
          return <img src={BankwireImage} alt='' className='local' />

        case PaymentMethodEnum.COMMUNITY_INSTANT_BANK_TRANSFER:
        case PaymentMethodEnum.DIRECT_INSTANT_BANK_TRANSFER:
        case PaymentMethodEnum.INSTANT_BANK_TRANSFER:
          return <img src={InstantBankTransferImage} alt='' className='local' />

        case PaymentMethodEnum.CREDIT_CARD:
          return <img src={CreditCardImage} alt='' className='local' />

        case PaymentMethodEnum.MOBILE_MONEY:
          return <img src={MobileMoneyImage} alt='' className='local' />

        default:
          return <img src={`${configuration.readBackendConfig()}${getPaymentMethodImageUrl(method)}`} alt='' />
      }
    }

    return null
  }

  return <ImageWrapper>{getImage()}</ImageWrapper>
}

const ImageWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 0;

  & > img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 1rem;
  }
`
