import { useDispatch } from 'react-redux'

import { Button } from 'mmfintech-portal-commons'
import { LabelValue } from './LabelValue'
import { ThunkDispatch } from 'redux-thunk'
import { PreviewGrid, PreviewTitle, WarningMessageWrapper } from './PreviewBankwire.styled'

import { actions } from 'mmfintech-checkout-commons'
import { getCountryName, isNotEmptyString, tr } from 'mmfintech-commons'

import { BankwireDepositDetails, PaymentSessionData } from 'mmfintech-commons-types'

interface PreviewBankwireProps {
  result: BankwireDepositDetails
  session: PaymentSessionData
  sessionId: string
}

export const PreviewBankwire = ({ result, session, sessionId }: PreviewBankwireProps) => {
  const { successUrl } = session || {}

  const {
    recipientAddress,
    bankName,
    bankAddress,
    iban,
    bicSwift,
    bankCode,
    branchCode,
    accountNumber,
    accountOwner,
    bankCountryCode,
    accountType,
    // documentId,
    // documentType,
    reference,
    intermediaryBankAccount,
    intermediaryBankName,
    intermediaryBankCountry,
    intermediaryBankSwiftCode
  } = result

  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  const handleRedirect = () => {
    void dispatch(actions.common.eventTrack(sessionId, 'redirected_back_to_merchant'))
    window.location.href = successUrl
  }

  // noinspection SpellCheckingInspection
  const formatAccountNumber = (): string | null =>
    accountNumber ? `${accountType === 'BANKGIRO' ? 'BG ' : ''}${accountNumber}` : null

  const getCountryCode = () => (bankCountryCode ? bankCountryCode : iban && iban.substring(0, 2) === 'GB' ? 'GBR' : '')

  return (
    <>
      <PreviewTitle data-test='bank-transfer-details'>
        {tr('FRONTEND.DEPOSIT.PREVIEW.TITLE', 'Bank Transfer Details')}
      </PreviewTitle>

      <PreviewGrid>
        <LabelValue
          label={tr('FRONTEND.DEPOSIT.PREVIEW.BANK_NAME', 'Bank Name')}
          value={bankName}
          dataTest='bank-name'
        />
        <LabelValue
          label={tr('FRONTEND.DEPOSIT.PREVIEW.BANK_ADDRESS', 'Bank Address')}
          value={bankAddress}
          dataTest='bank-address'
        />
        <LabelValue label={tr('FRONTEND.DEPOSIT.PREVIEW.IBAN', 'IBAN')} value={iban} dataTest='iban' />
        <LabelValue
          label={tr('FRONTEND.DEPOSIT.PREVIEW.BIC_SWIFT', 'BIC/SWIFT')}
          value={bicSwift}
          dataTest='bic-swift'
        />
        <LabelValue
          label={tr(
            `FRONTEND.DEPOSIT.PREVIEW.BANK_NUMBER_${getCountryCode()}`,
            tr('FRONTEND.DEPOSIT.PREVIEW.BANK_NUMBER', 'Bank code')
          )}
          value={bankCode}
          dataTest='bank-number'
        />
        <LabelValue
          label={tr('FRONTEND.DEPOSIT.PREVIEW.BRANCH_CODE', 'Branch code')}
          value={branchCode}
          dataTest='branch-code'
        />
        <LabelValue
          label={tr('FRONTEND.DEPOSIT.PREVIEW.ACCOUNT_NUMBER', 'Account Number')}
          value={formatAccountNumber()}
          dataTest='account-number'
        />
        <LabelValue
          label={tr('FRONTEND.DEPOSIT.PREVIEW.ACCOUNT_OWNER', 'Account Owner')}
          value={accountOwner}
          dataTest='account-owner'
        />
        <LabelValue
          label={tr('FRONTEND.DEPOSIT.PREVIEW.OWNER_ADDRESS', 'Owner Address')}
          value={recipientAddress}
          dataTest='recipient-address'
        />
        <LabelValue
          label={tr('FRONTEND.DEPOSIT.PREVIEW.REFERENCE', 'Reference')}
          value={reference}
          dataTest='reference'
        />

        <LabelValue
          label={tr('FRONTEND.DEPOSIT.PREVIEW.INTERMEDIARY_BANK_ACCOUNT', 'Intermediary bank account')}
          value={intermediaryBankAccount}
          dataTest='intermediary-bank-account'
        />
        <LabelValue
          label={tr('FRONTEND.DEPOSIT.PREVIEW.INTERMEDIARY_BANK_NAME', 'Intermediary bank name')}
          value={intermediaryBankName}
          dataTest='intermediary-bank-name'
        />
        <LabelValue
          label={tr('FRONTEND.DEPOSIT.PREVIEW.INTERMEDIARY_BANK_COUNTRY', 'Intermediary bank country')}
          value={getCountryName(intermediaryBankCountry)}
          dataTest='intermediary-bank-country'
        />
        <LabelValue
          label={tr('FRONTEND.DEPOSIT.PREVIEW.INTERMEDIARY_BANK_BIC_SWIFT', 'Intermediary bank BIC/SWIFT')}
          value={intermediaryBankSwiftCode}
          dataTest='intermediary-bank-bic-swift'
        />
      </PreviewGrid>

      {isNotEmptyString(reference) && (
        <WarningMessageWrapper>
          {tr(
            'FRONTEND.DEPOSIT.PREVIEW.WARNING1',
            'Please pay attention to enter your unique reference to your transaction. If the reference is blank or wrong, the transaction will be declined and you will need to enter the correct reference and try again.'
          )}
        </WarningMessageWrapper>
      )}

      {successUrl ? (
        <div className='buttons'>
          <Button
            type='button'
            color='primary'
            onClick={handleRedirect}
            text={tr('FRONTEND.BUTTONS.CLOSE', 'Close')}
            data-test='button-back'
          />
        </div>
      ) : null}
    </>
  )
}
