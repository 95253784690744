// noinspection SpellCheckingInspection

import styled from 'styled-components'

export const ButtonWrapper = styled.div`
  cursor: pointer;
  position: relative;

  height: 8rem;
  width: 100%;

  display: grid;
  grid-template-columns: 7.2rem 1fr 6rem;
  margin-top: 1.6rem;

  color: #454a54;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border: 1px solid #e1e5ea;
  border-radius: 1rem;

  &.selected {
    background-color: #e9eef5;
    border-color: #e9eef5;

    & .arrow {
      display: flex;
    }
  }
`

export const ButtonContent = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-right: 1rem;

  .description {
    font-size: 1.2rem;
    font-weight: 400;
  }
`

export const ButtonArrow = styled.div`
  height: 100%;
  width: 100%;

  background: #1e63ff;
  border: 1px solid #1e63ff;
  border-radius: 0 1rem 1rem 0;

  display: none;
  align-items: center;
  justify-content: center;
`
